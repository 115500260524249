import _i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'

export type TLanguage = 'ru-RU' | 'en-US'

const versionize = (version: string | undefined) =>
  version ? '?v=' + version : ''

const i18n = _i18n

const defaultModules = ['common', 'ag-grid']

export const setDefaultLanguage = (
  lng: TLanguage,
  version?: string,
  modules: Record<
    string,
    (lng: TLanguage) => Promise<{ default: Record<string, string> }>
  > = {},
  loadLocal = true
) => {
  const fallbackNS = [...defaultModules, ...Object.keys(modules)]

  /**
   * @description Функция проверяет текущий namespace, если он локальный ( зависим от проекта ),
   * то возвращает локальный путь до него в виде строки,
   * если это модуль, то undefined, это прокидывает до следующего резолвера
   */
  const loadPath = (_: TLanguage[], [ns]: string[]) =>
    fallbackNS.includes(ns)
      ? undefined
      : '/locales/{{lng}}/translation.json' + versionize(version)

  /**
   * @description Функция проверяет нахождения модуля, — локальный или же зависим от виджета,
   * и возвращает промис с зарезолвенным путем
   */
  const res = (lng: TLanguage, ns: string) =>
    defaultModules.includes(ns)
      ? import(`./${ns}/locales/${lng}/translation.json`)
      : modules[ns](lng)

  const ns = loadLocal
    ? ['localNs', ...fallbackNS] // its length'd be fallbackNS.length + 1
    : fallbackNS

  return i18n
    .use(ChainedBackend)
    .use(initReactI18next)
    .init<ChainedBackendOptions>({
      lng,
      ns,
      fallbackNS,

      returnNull: false,

      load: 'currentOnly',
      fallbackLng: false,

      nsSeparator: false,
      keySeparator: false,

      react: { useSuspense: false },
      interpolation: { escapeValue: false }, // not needed for react as it escapes by default

      backend: {
        backends: [HttpBackend, resourcesToBackend(res)],
        backendOptions: [{ loadPath }]
      }
    })
}

export {
  useTranslation,
  withTranslation,
  initReactI18next,
  I18nextProvider
} from 'react-i18next'

export default i18n
